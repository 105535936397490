export const FilterService = {
  defaultData() {
    return {
      s: 'updated_at desc',
      is_archived: 'false',
    };
  },
  sort(filters, column) {
    const newFilter = filters;
    let order = 'asc';
    if (filters.s.split(' ')[1] === 'asc') {
      order = 'desc';
    }

    newFilter.s = `${column} ${order}`;
    return newFilter;
  },
  filter(filters, columnName, data) {
    const newFilter = filters;

    if (data !== '' && data !== null) {
      newFilter[columnName] = data;
      return newFilter;
    }

    delete newFilter[columnName];
    return newFilter;
  },
  controller(type, filters, column, data) {
    if (type === 'sort') {
      return this.sort(filters, column);
    } if (type === 'filter') {
      return this.filter(filters, column, data);
    }
    return 'Error';
  },
  build(params, filters) {
    const newParams = params;
    Object.keys(filters).forEach((objectKey) => {
      newParams[`q[${objectKey}]`] = filters[objectKey];
    });

    return newParams;
  },

};

export default FilterService;
